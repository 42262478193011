import { useCategoriesStore } from "~/store/categoriesStore";

export const useHiddenPricePolicies = () => {
  const hiddenPricePolicies = [];
  const route = useRoute();

  hiddenPricePolicies.push(() => {
    if (!isRouteMatched("category")) {
      return false;
    }

    const category = useCategoriesStore().getCategoryBySlug(
      route.params.categorySlug,
    );

    if (null === category.promotion) {
      return false;
    }

    return isPromotionEnded(category);
  });

  return hiddenPricePolicies;
};
